
.content
  display: flex
  align-items: center
  justify-content: center
  flex-direction: column
  text-align: center
  min-height: calc(100vh - 80px)
  height: max-content
  padding-top: 40px
